// import React from 'react'
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import HomePage from './components/pages/HomePage';
// import HireDeveloperPage from './components/pages/HireDeveloperPage';
// import OurWorkPage from './components/pages/OurWorkPage';
// import AboutUs from './components/pages/AboutUs';
// import ServicesPage from './components/pages/ServicesPage';
// import ContactUs from './components/pages/ContactUs';
// import { useLocation } from 'react-router-dom';
// import { useEffect, useRef } from 'react';
// import { ToastContainer } from 'react-toastify';

// const AppRoutes = () => {
//   const ScrollToTop = () => {
//     const pathname = useLocation();
//     const prevPathname = useRef();
   
//     useEffect(() => {
//       if (prevPathname.current !== pathname) {
//         window.scrollTo(0, 0);
//         prevPathname.current = pathname;
//       }
//     }, [pathname]);
   
//     return null;
//   };
//   return (
//     <BrowserRouter>
//        <ScrollToTop />
//        <ToastContainer/>
//     <Routes>
//       <Route path="/" element={<HomePage/>}/>
//       <Route path="/hire-developer" element={<HireDeveloperPage/>}></Route>
//       <Route path="/our-work" element={<OurWorkPage/>}/>
//       <Route path="/about-us"  element={<AboutUs/>} />
//       <Route path="/services"  element={<ServicesPage/>} />
//       <Route path="/contact-us"  element={<ContactUs/>} />

      
     
//     </Routes>
//   </BrowserRouter>
//   )
// }

// export default AppRoutes


import React, { Suspense, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';


const HomePage = React.lazy(() => import('./components/pages/HomePage'));
const HireDeveloperPage = React.lazy(() => import('./components/pages/HireDeveloperPage'));
const OurWorkPage = React.lazy(() => import('./components/pages/OurWorkPage'));
const AboutUs = React.lazy(() => import('./components/pages/AboutUs'));
const ServicesPage = React.lazy(() => import('./components/pages/ServicesPage'));
const ContactUs = React.lazy(() => import('./components/pages/ContactUs'));

const ScrollToTop = () => {
  const pathname = useLocation();
  const prevPathname = useRef();

  useEffect(() => {
    if (prevPathname.current !== pathname) {
      window.scrollTo(0, 0);
      prevPathname.current = pathname;
    }
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  return (
    <div>
      
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer />
     
      <Suspense fallback={<div>loading ..</div>}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/hire-developers" element={<HireDeveloperPage />} />
          <Route path="/our-works" element={<OurWorkPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
    </div>
  );
};

export default AppRoutes;

