import logo from './logo.svg';
import './App.css';
import AppRoutes from './AppRoutes';


function App() {

  return (
    <div>
 
 
   <AppRoutes/>
   </div>
  );
}

export default App;
