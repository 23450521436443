
import { createTheme } from '@mui/material/styles';

const Theme = createTheme({

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      custom: 1400, 
    },
  },
  palette: {
    background: {
      default: "#FF5029",
      bg: '#FEF3E2',
      border: "#FFE4BB",
      descp: "#6A6A6A",
      footer: "#FF8C33",
      color: '#363636',
      backgroundcolor: "#FDFCF9",
      text: "#FFFFFF",
      headbackground: '#fff4e6', 
    },
  },
  typography: {
    h1: {
      fontFamily: "ADLaM Display",
      fontWeight: 400,
      [createTheme().breakpoints.down('sm')]: {
        fontSize: '1.75rem',
        lineHeight: '2.25rem', 
      },
      [createTheme().breakpoints.between('sm', 'md')]: {
        fontSize: '1.875rem',
        lineHeight: '2.5rem', 
      },
      [createTheme().breakpoints.up('md')]: {
        fontSize: '2.5rem', 
        lineHeight: '4rem', 
      },
    },
    h6:{
      fontSize:"1rem",
      fontWeight: '400', 
      fontFamily: "Ruwudu",
      [createTheme().breakpoints.down('sm')]: {
        lineHeight: '1.625rem', 
      },
      [createTheme().breakpoints.up('md')]: {
        lineHeight: '1.875rem',
        fontSize:'1.25rem' 
      },
    },
    head:{
fontWeight: '400',
display:'flex',
fontFamily: "ADLaM Display",
[createTheme().breakpoints.down('sm')]: {
  fontSize: '0.75rem',
  lineHeight: '0.9375rem',
},
[createTheme().breakpoints.between('sm', 'md')]: {
  fontSize: '0.875rem', 
  lineHeight: '0.9375rem', 
},
[createTheme().breakpoints.up('md')]: {
  fontSize: '1rem', 
  lineHeight: '1.25rem', 
},

    },
   
    
    caption1: {
      fontFamily: "ADLaM Display",
    },
    caption2: {
      fontFamily: "Ruwudu",
    },
    caption3: {
      fontFamily: "Inter",
    },

    sectionHeading: {

      [createTheme().breakpoints.down('sm')]: {
        fontSize: '28px',
        lineHeight: '30px',
      },
      [createTheme().breakpoints.between('sm', 'md')]: {
        fontSize: '28px',
        lineHeight: '30px',
      },
      [createTheme().breakpoints.up('md')]: {
        fontSize: '40px',
        lineHeight: '64px',
      },
      fontWeight: '400',
     
      color: theme => theme.palette.background.color,
      fontFamily: "ADLaM Display",
    },
    sectionSubHeading: {
     
      [createTheme().breakpoints.down('sm')]: {
        fontSize: '15px',
        lineHeight: '180%',
        marginTop: '50px',
       
      },
      [createTheme().breakpoints.between('sm', 'md')]: {
        fontSize: '15px',
        lineHeight: '180%',
        marginTop: '50px',
     
      },
      [createTheme().breakpoints.up('md')]: {
        fontSize: '18px',
        lineHeight: '180%',
        marginTop: '50px',
     
      },
      fontWeight: '400',
    
      color: theme => theme.palette.background.descp,
      fontFamily: "Ruwudu",
    },
   
  },
  customStyles: {
    sectionSubHeadingMargin: {
      mt: { xs: '16px', sm: '16px', md: '50px' },
    },
  },
 
});

export default Theme;





